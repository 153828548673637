import {
  Alert,
  Button,
  Collapse,
  Dropdown,
  Modal,
  Tab,
} from 'bootstrap';

// Make available globally
window.Alert = Alert;
window.Button = Button;
window.Collapse = Collapse;
window.Dropdown = Dropdown;
window.Modal = Modal;
window.Tab = Tab;
